<template>
	<div class="header">
		 
		<div class="nav">  
			<div class="toplogo">
				<router-link to="/" class="logo-wrap">
					<img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
				</router-link>
			</div> 
			<nav>
				<ul>
					<li
						v-for="(nav_item, nav_index) in navList"
						:key="nav_index"
						:class="nav_item.url == navSelect ? 'router-link-active' : ''"
						@click="navUrl(nav_item.url, nav_item.is_blank)"
					>
						<span>{{ nav_item.nav_title }}</span>
					</li>
				</ul>
			</nav>
			<div class="topright">
			    <router-link v-if="!logined" to="/login" class="notlogin">您好，请登录</router-link>
			    <div v-if="logined" class="member-info">
			        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
			        <span @click="logout">退出</span>
			    </div>
			    <router-link v-if="!logined" to="/register">免费注册</router-link>
			</div>
		</div>
	</div>
</template>

<script>
 
import { tree , categoryConfig} from '@/api/goods/goodscategory';
import { navList } from '@/api/website';
import {
	mapGetters
} from "vuex"
export default {
	props: {
		forceExpand: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters(['is_show', 'siteInfo', "member"]),
		logined: function() {
		    return this.member !== undefined && this.member !== "" && this.member !== {}
		}


	},
	data() {
		return {
			isShopHover: false,
			isIndex: false,
			thisRoute: '',
			goodsCategoryTree: [],
			categoryConfig:{},
			selectedCategory: -1,
			navList: [],
			navSelect: '',
			isHide:false
		};
	},
	components: { 
		mapGetters
	},
	beforeCreate() {},
	created() { 
		this.$store.dispatch('cart/cart_count');
		this.getCategoryConfig();
		// this.getTree();
		this.nav();

		if (this.$route.path == '/' || this.$route.path == '/index'){
			this.isIndex = true;
		}
		this.$store.dispatch("member/member_detail")

	},
	watch: { 
		$route: function(curr) { 
			 
			this.initNav(curr.path);
			let judgeLength = localStorage.getItem('isAdList')
			if (this.$route.path == '/' || this.$route.path == '/index') this.isIndex = true;
			else this.isIndex = false;
 
			if (curr.path == '/list') {
				 this.navSelect = '/list?category_id=0&level=1';
			}
		}
	},
	methods: {
		// 获取配置
		getCategoryConfig(){
			categoryConfig({
				
			})
			.then(res => {
				if (res.code == 0 && res.data) {
					this.categoryConfig = res.data;
					this.getTree(res.data);
				}
			})
			.catch(err => {
				this.$message.error(err.message);
			});
		},
		getTree(categoryConfig) {
			tree({
				level: 3,
				template: 2
			})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.goodsCategoryTree = res.data || [];
						for(let i =0;i<this.goodsCategoryTree.length;i++) {
							if(this.goodsCategoryTree[i].child_list > 3) {
								this.isHide = true
							}
						}
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		nav() {
			navList({})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.navList = res.data;
						for (let i in this.navList) {
							this.navList[i]['url'] = JSON.parse(this.navList[i]['nav_url']).url;
						}
						let tmppath=this.$route.path;
						if (this.$route.path == '/list') {
							 tmppath= '/list?category_id=0&level=1';
						}
						this.initNav(tmppath);
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		navUrl(url, target) {
			if (!url) return;
			if (url.indexOf('http') == -1) {
				if (target) this.$router.pushToTab({ path: url });
				else this.$router.push({ path: url });
			} else {
				if (target) window.open(url);
				else window.location.href = url;
			}
		},
		initNav(path) {
			for (let i in this.navList) {
				if (this.navList[i]['url'] == path) {
					this.navSelect = path;
					continue;
				}
			}
		},
		
		//鼠标移入显示商品分类
		shopHover() {
			this.isShopHover = true;
		},
		//鼠标移出商品分类隐藏
		shopLeave() {
			this.isShopHover = false;
		},
		logout() {
		    this.$store.dispatch("member/logout")
		}
	}
};
</script>

<style scoped lang="scss">
.header {
	width: 100%;
	height: 80px;
	background-color: #F5F9FA;
 
	.shadow {
		box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
	}
	.border {
		border: 1px solid #f5f5f5;
	}

	.nav {
		width: 1210px;
		height: 61px;
		margin: 0 auto;
		position: relative;
		 
		.toplogo{
			width:400px;
			height:26px; 
			padding-top:15px;
			float:left;
			 
			image{
				width:200px;
				height:41px;
			}
		}
		.topright{
			width:200px;
			height:60px; 
			float:right;
			font-weight:bold;
			line-height: 65px;
			.notlogin{
				margin-right:20px;
				 
			}
		}
		.shop-sort {
			width: 210px;
			height: 41px;
			color: #fff;
			background-color: $base-color;
			float: left;
			padding: 10px 10px 11px 10px;
			box-sizing: border-box;
			a {
				font-size: 14px;
				line-height: 20px;
				float: left;
				color: #fff;
			}
			i {
				font-size: 14px;
				float: right;
			}
		}
		.shop-list-content{
			width: 210px;
			height: 500px;
			position: absolute;
			left: 0;
			top: 41px;
			background-color: #333;
			display: none;
			padding: 0;
			box-sizing: border-box;
			font-size: $ns-font-size-base;
			z-index: 10;
			color:  #FFFFFF;
			&::-webkit-scrollbar{
				display: none;
			}
			// overflow: hidden;
			.shop-list {
				width: 210px;
				height: 500px;
				overflow-y: auto;
				overflow-x: hidden;
				// box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
				&::-webkit-scrollbar{
					display: none;
				}
				a:hover {
					color: $base-color;
				}
				.list-item {
					padding-left: 12px;
					box-sizing: border-box;
					// min-height:50px;
					// height: 45px;
					// line-height: 50px;
					padding:13px;
					a {
						display: flex;
						justify-content: space-between;
						align-items: center;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// width: 95%;
						// display: block;
						color:  #FFFFFF;
						>div{
							display: flex;
							align-items: center;
						}
					}
					&:hover {
						background-color: #000;
						-webkit-transition: 0.2s ease-in-out;
						-moz-transition: -webkit-transform 0.2s ease-in-out;
						-moz-transition: 0.2s ease-in-out;
						transition: 0.2s ease-in-out;
						a:hover {
								color: #FD274A;
						}
					}
					span:hover {
						color: #FD274A;
					}
					.category-img{
						width:17px;
						height: 17px;
						margin-right: 10px;
					}
					.category-name{
						overflow:hidden;
						text-overflow:ellipsis;
						white-space:nowrap;
					}
					.item-itm {
						font-size: 14px;
						line-height: 15px; 
						height: 28px;
						overflow: hidden;
						
						a{
							margin-top: 5px;
							margin-right: 10px;
							color:#BFBFBF;
							&:hover{
								color: #FD274A !important;
							}
						}
						
						&.item-itm-img{
							margin-left: 27px;
						}
					}
					.cate-part {
						display: none;
						position: absolute;
						left: 210px;
						top: 0;
						z-index: auto;
						// width: 998px;
						width: 760px;
						height: 498px;
						overflow-y: auto;
						border: 1px solid #f7f7f7;
						background-color: #fff;
						-webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						-moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						-webkit-transition: top 0.25s ease;
						-o-transition: top 0.25s ease;
						-moz-transition: top 0.25s ease;
						transition: top 0.25s ease;
						&.show {
							display: block;
						}
						&::-webkit-scrollbar{
							display: none;
						}
					}
					.cate-part-col1 {
						float: left;
						width: 100%;
						.cate-detail-item {
							position: relative;
							min-height: 36px;
							padding-left: 20px;
							&:last-child{
								margin-bottom: 30px;
							}
							.cate-detail-tit {
								margin-top:30px;
								margin-right: 20px;
								white-space: nowrap;
								text-overflow: ellipsis;
								font-weight: 700;
								a {
									display: block;
									color: #333333;
								}
								a:hover{
									color: #FD274A;
								}
								
							}
							.cate-detail-con {
								overflow: hidden;
								padding: 6px 0 6px 17px;
								display: flex;
								flex-wrap: wrap;
								// border-top: 1px dashed #eee;
								a {
									justify-content: start;
									font-size: 12px;
									height:30px;
								    /* float: left; */
								    margin: 4px 40px 4px 0;
								    padding: 0 10px;
								    white-space: nowrap;
								    line-height: 30px;
								    color: #666;
									width: calc((100% - 120px) / 4);
									display: flex;
									box-sizing: border-box;
									margin-top: 20px;
									.cate-detail-img{
										width:30px;
										height: 30px;
										margin-right: 10px;
									}
									&:nth-child(4n+4){
										margin-right: 0;
									}
								}
								a:hover{
									color: #FD274A;
								}
							}
							&:first-child .cate-detail-con {
								border-top: none;
							}
						}
					}
			
					// .sub-class-right {
					// 	display: block;
					// 	width: 240px;
					// 	height: 439px;
					// 	float: right;
					// 	border-left: solid 1px #e6e6e6;
					// 	overflow: hidden;
					// 	.adv-promotions {
					// 		display: block;
					// 		height: 441px;
					// 		margin: -1px 0;
					// 		a {
					// 			background: #fff;
					// 			display: block;
					// 			width: 240px;
					// 			height: 146px;
					// 			border-top: solid 1px #e6e6e6;
					// 			img {
					// 				background: #d3d3d3;
					// 				width: 240px;
					// 				height: 146px;
					// 			}
					// 		}
					// 	}
					// }
				}
			}
		}
		
		.shop-list-active {
			display: block;
		}
		nav {
			width: 600px;
			height: 60px;
			 
			float: left;
			font-size: 14px; 
			line-height:50px;
			ul {
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					padding: 8px 24px; 
					color:#333333;
					a {
						 
						&:hover {
							color: #333333;
						}
					}
				}
				li:hover {
					color: #333333;
					font-weight: bold;
				}
				.router-link-active {
					color: #333333;
					font-weight: bold;
					border-bottom: 2px solid  #333333;
				}
			}
		}
	}
}
</style>
